import React from "react";

const RatesPageSection = ({ title, description, children }) => (
  <div className="max-w-7xl mx-auto mt-32 sm:mt-40 px-4 bg-white sm:px-6 lg:px-8">
    <h2 className="text-center text-3xl font-extrabold text-gray-900 md:text-5xl sm:leading-none sm:tracking-tight">
      {title}
    </h2>
    {description && (
      <p className="text-center mx-auto mt-6 max-w-3xl text-xl text-gray-500">
        {description}
      </p>
    )}

    <div className="mt-8 sm:mt-12 grid grid-cols-1 gap-8">{children}</div>
  </div>
);

export default RatesPageSection;
