import React from "react";
import Header from "../components/sections/Header";
import Footer from "../components/sections/Footer";
import PageTitle from "../components/elements/PageTitle";
import RatesPageSection from "../components/sections/RatesPageSection";
import RatesPageItem from "../components/elements/RatesPageItem";

const ratesRuPage = () => (
  <>
    <PageTitle lang="ru" title="Тарифы" description={``} />
    <Header lang="ru" />
    <RatesPageSection title="Тарифы" description="">
      <RatesPageItem
        title="Профессиональное сопровождение"
        description="Рекомендуется для небольших проектов, где не предъявляются высокие требования к непрерывности работы"
        features={[
          "Развертывание производится на одном сервере на любом хостинге заказчика.",
          "Круглосуточный мониторинг сервера с доступом заказчика в дашборды мониторинга.",
          "Доступ к телеграм боту информирующему о проблемах",
          "Live-chat c технической поддержкой (пн-пт, 10-20 мск, реакция не более чем через 2 часа)",
          "Компенсация за простой инфраструктуры",
          "Организация резервного копирования файловой системы",
          "Инфраструктура предоставляется как код, что позволяет иметь актуальную документацию на инфраструктуру в виде кода",
          "Предоставляются доступы к логам",
          "Командой ведется работа по проактивному решению проблем инфраструктуры на основе метрик мониторинга",
        ]}
        price="8 000 ₽"
        frequency="/мес"
        linkText="Выбрать"
        linkPath="/ru/#contacts"
      />
      <RatesPageItem
        title="Профессиональное сопровождение с отказоустойчивостью"
        description="Рекомендуется для критически важных систем с высокими требованиями к непрерывности работы"
        features={[
          "В данном варианте разворачивается отказоустойчивый кластер на 3 серверах с балансировщиком нагрузки",
          "Вариант включает все то, что включено в профессиональное сопровождение, однако в связи с использованием отказоустойчивого кластера время восстановления составляет 1 минуту",
          "В пакет включён 1 час работ технической поддержки (дополнительные работы вне рамок пакета)",
          "Однократно производится отработка методики переключения на резервный сервер",
          "Для критичных систем применяются автоматические алгоритмы отказоустойчивости",
        ]}
        price="30 000 ₽"
        frequency="/мес"
        linkText="Выбрать"
        linkPath="/ru/#contacts"
      />
      <RatesPageItem
        title="Профессиональное сопровождение с отказоустойчивостью с кастомизацией"
        description="Рекомендуется там, где есть особые требования к настройке инфраструктуры"
        features={[
          "Вариант аналогичен профессиональному сопровождению с отказоустойчивостью",
          "Инфраструктура в данном случае не ограничена и может включать любое количество серверов",
          "В пакет включено 2 часа технической поддержки",
          "Расширенная отработка методики переключения на резервные мощности",
          "Дополнительное резервирование объектов инфраструктуры до 3 объектов",
          "Репликация базы данных",
        ]}
        price="от 40 000 ₽"
        frequency="/мес"
        linkText="Выбрать"
        linkPath="/ru/#contacts"
      />
    </RatesPageSection>
    <Footer lang="ru" />
  </>
);

export default ratesRuPage;
