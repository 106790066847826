import { CheckCircleIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import React from "react";

const RatesPageItem = ({
  title,
  description,
  features,
  price,
  frequency,
  linkText,
  linkPath,
}) => (
  <div className="bg-white">
    <div className="relative">
      <div className="absolute inset-0 h-1/2" />
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
          <div className="bg-white px-6 py-8 lg:p-12">
            <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
              {title}
            </h3>
            <div className="mt-8">
              <div className="flex items-center">
                <div className="flex-1 border-t-2 border-gray-200" />
              </div>
              <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-1 lg:gap-x-8 lg:gap-y-5">
                {features.map((feature) => (
                  <li key={feature} className="flex items-start lg:col-span-1">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-5 w-5 text-purple-light"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-3 text-sm text-gray-700">{feature}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="py-8 px-6 flex-1 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12 min-w-[30%]">
            <div className="block items-baseline justify-center text-4xl font-extrabold text-gray-900">
              <span>{price}</span>
              <span className="ml-1 text-lg font-medium text-gray-500">
                {frequency}
              </span>
            </div>
            <p className="mt-4 text-sm text-left font-medium text-gray-500">
              {description}
            </p>
            <div className="mt-6">
              <div className="rounded-md shadow">
                <Link
                  to={linkPath}
                  className="flex items-center justify-center px-5 py-3 rounded-md bg-gradient-to-r from-btn-blue-dark to-btn-blue-light hover:from-btn-blue-light hover:to-btn-blue-dark text-white"
                >
                  {linkText}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default RatesPageItem;
